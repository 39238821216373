import { ChevronIcon } from '@allurion/ui';
import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { TrackedButton, TrackedIconButton } from 'src/analytics/TrackedUI';

import styles from './Pagination.module.scss';

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void);
  defaultPageSize: number;
  lastPage: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>> | ((pageSize: number) => void);
  pageSizeLabel: string;
};

export function Pagination({ page, setPage, lastPage, setPageSize, defaultPageSize }: Props) {
  const intl = useIntl();
  const [viewAll, setViewAll] = useState(false);

  const nextPage = () => {
    if (page >= lastPage) {
      return;
    }

    setPage(page + 1);
  };

  const prevPage = () => {
    if (page <= 1) {
      return;
    }

    setPage(page - 1);
  };

  const toggleViewAll = () => {
    const nextViewAll = !viewAll;

    setViewAll(nextViewAll);
    if (nextViewAll) {
      setPageSize(Number.MAX_SAFE_INTEGER);
      setPage(1);
    } else {
      setPageSize(defaultPageSize);
    }
  };

  return (
    <div className={styles.container}>
      {viewAll ? (
        <div className={styles.pageSizes}>
          <TrackedButton
            label={intl.formatMessage({
              id: 'manage-patients-table.hide-all',
              defaultMessage: 'View less',
            })}
            trackLabel="hide-all"
            onClick={toggleViewAll}
            rightIcon={<ChevronIcon direction="up" />}
            size="xs"
            variant="secondary"
          />
        </div>
      ) : (
        <>
          <div className={styles.pageSizes}>
            <TrackedButton
              label={intl.formatMessage({
                id: 'manage-patients-table.view-all',
                defaultMessage: 'View All',
              })}
              trackLabel="view-all"
              onClick={toggleViewAll}
              rightIcon={<ChevronIcon direction="down" />}
              size="xs"
              variant="secondary"
            />
          </div>

          <div className={styles.pageSelector}>
            <span>
              {intl.formatMessage(
                {
                  id: 'manage-patients.table.page-index',
                  defaultMessage: 'Page {currentPage} of {totalPages}',
                },
                { currentPage: page, totalPages: lastPage || 1 }
              )}
            </span>

            <div className={styles.pageSelectorButtons}>
              <TrackedIconButton
                onClick={prevPage}
                disabled={page === 1}
                icon={<ChevronIcon direction="left" />}
                size="xs"
                variant="secondary"
                trackLabel="previous-page"
              />
              <TrackedIconButton
                onClick={nextPage}
                disabled={page >= lastPage}
                icon={<ChevronIcon direction="right" />}
                size="xs"
                variant="secondary"
                trackLabel="next-page"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
